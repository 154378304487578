import httpClient from '../httpClient';

const fetchBatchStatus = (batchId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/batches/${batchId}/status`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export { fetchBatchStatus };
