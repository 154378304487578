<template>
  <div>
    <v-btn
      color="primary"
      small
      class="mb-5 full-width"
      :loading="relaticsSyncStatus === 'loading'"
      :disabled="relaticsSyncStatus === 'loading'"
      @click="syncWithRelatics"
    >
      Sync with relatics
    </v-btn>

    <div v-if="batchProgress" class="d-flex">
      <v-progress-linear height="20" :value="batchProgress" stream rounded>
        <template #default="{ value }">
          <strong>{{ Math.ceil(value) }}%</strong>
        </template>
      </v-progress-linear>
    </div>

    <dynamic-data-table
      v-if="selectedSbsObject && requirementsToggle"
      class="ant-glass-background mb-5"
      table-title="Requirements"
      :table-records="requirementRecords"
      :table-headers="requirementColumns"
      :is-loading="requirementsLoading"
    />
  </div>
</template>

<script>
import DynamicDataTable from '@/components/DynamicDataTable';
import { mapGetters } from 'vuex';
import { executeCustomModuleCall } from '@/services/api/module.api';
import { RELATICS } from '@/modules/modules';
import { fetchBatchStatus } from '@/services/api/batches.api';

export default {
  name: 'RelaticsSidebar',
  components: {
    DynamicDataTable,
  },
  props: {
    requirementRecords: {
      type: Array,
      default: () => [],
    },
    requirementColumns: {
      type: Array,
      default: () => [],
    },
    requirementsToggle: {
      type: Boolean,
      default: false,
    },
    requirementsLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      syncDialog: false,
      syncVersionLabel: undefined,
      relaticsSyncStatus: '',
      batchProgress: null,
    };
  },
  computed: {
    ...mapGetters(['project', 'relaticsSettings', 'selectedSbsObject']),

    moduleId() {
      return this.project.modules.find((module) => module.route === RELATICS)
        .id;
    },
  },
  methods: {
    async syncWithRelatics() {
      this.relaticsSyncStatus = 'loading';
      this.batchProgress = 0;
      let body = {
        url: this.relaticsSettings.url,
        operation: this.relaticsSettings.operation,
        workspace: this.relaticsSettings.workspace,
        authentication: this.relaticsSettings.authentication,
      };
      try {
        let batch = await executeCustomModuleCall(
          this.project.id,
          this.moduleId,
          'syncWithRelatics',
          body
        );
        await this.checkBatchStatus(batch);
        this.relaticsSyncStatus = 'success';
        await this.$store.dispatch('loadSbsTree', {
          projectId: this.project.id,
        });
      } catch (error) {
        this.relaticsSyncStatus = 'error';
        this.$store.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    },
    checkBatchStatus(object) {
      return new Promise(async (resolve, reject) => {
        let response = await fetchBatchStatus(object.batch);
        this.batchProgress = response.progress_percentile;
        if (response.status !== 'finished') {
          setTimeout(() => {
            resolve(this.checkBatchStatus(object));
          }, 3000);
        } else {
          this.batchProgress = null;
          resolve(true);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
